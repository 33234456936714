import React, { Component } from "react";
import ReactPlayer from "react-player";
import "./App.css";
import "./i18n";
import { CONTACT_WEBINAR, WATCHED_WEBINAR } from "./config/app.config";

import Modal from "./components/Modal";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DNA } from 'react-loader-spinner'
import MOCK from './config/mock.json'

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Description
const DescriptionText = ({ state }) => {
  return (
    <span>
      <div className="desc_text_one">{state?.author}</div>
      <div className="desc_text_two">{state?.title}</div>
      <div className="desc_text_three">{state?.description}</div>
    </span>
  );
};

// Header image and title
const HeadText = ({ state }) => {
  return (
    <div className="header">
      <div className="img_container">
        <img
          className="younity_img"
          src={require("./assets/icons/younity.png")}
          alt="Fetching"
        />
      </div>
      <div className="head_container">
        <span className="head_container_two">
          <span className="first_head">{state?.liveTitle}</span>
          <span className="second_head">
            {state?.author} {state?.title}
          </span>
        </span>
      </div>
    </div>
  );
};

// Coming text
const Comingsoon = () => {
  const { t } = useTranslation();
  return <div className="comingsoon">{t("description.coming_soon")}</div>;
};

// Session expired waiting for next session
const WaitForNextSession = () => {
  const { t } = useTranslation();
  return <div className="comingsoon">{t("description.next_session")}</div>;
};

// Ads modal
const AdsModal = ({ showModal, toggleModal, state }) => {
  return (
    <Modal isOpen={showModal} onClose={toggleModal}>
      <div className="">
        <div className="flex">
          <img src={state.currentAd?.image} alt="Fetching" className="imgAds" />
          <div className="fullWidth">
            <div className="flex center">
              <h2 className="h2Ads">{state.currentAd?.adRate}</h2>
              <p className="line-through">{state.currentAd?.adStartRate}</p>
            </div>
            <div className="center">
              <button className="adsButton">
                {state.currentAd?.adLinkText}
              </button>
            </div>
            <div className="center">
              <p className="sub-text">
                {state.currentAd?.adTermsCondition}
              </p>
            </div>
          </div>
        </div>
        <div className="flex center">
          <h3 className="h2Ads">
            {state.currentAd?.adBottomTextFirst}
          </h3>
          <h3 className="pl5">
            {state.currentAd?.adBottomTextSecond}
          </h3>
        </div>
      </div>
    </Modal>
  );
};

class App extends Component {
  state = {
    url: "",
    playing: true,
    volume: 0.8,
    muted: true,
    duration: 0,
    isFullScreen: false,
    showModal: false,
    AdsModal: false,
    description: '',
    isLoading: true,
    authorImage: '',
    author: '',
    liveTitle: '',
    title: '',
    videoInterval: 0,
    ads: [],
    currentAd: '',
    detailId: '',
    currentDuration: '',
    showAds: false,
    webinarMinTime: 0,
    isHovered: false,
  }

  // Fetch data from server
  fetchVideoUrl = () => {
    const url = window.location.href;
    const subUrl = url.split('webinar/')[1];
    const webinarId = subUrl ? subUrl.split('/')[0] : null;
    const versionId = url.split('version/')[1];
    if (!webinarId || !versionId) {
      toast("Please check webinar link");
      return
    }
    const apiUrl = `${BASE_URL}${CONTACT_WEBINAR}${webinarId}&versionId=${versionId}`;
    fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          this.setState({ isLoading: false });
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // let data = MOCK
        this.setState({
          url: data?.link,
          authorImage: data?.authorImage,
          isLoading: false,
          author: data?.author,
          liveTitle: data?.liveTitle,
          title: data?.title,
          description: data?.description,
          videoInterval: data?.videoInterval,
          ads: data?.ads,
          detailId: data?.detailId,
          webinarMinTime: data?.webinarMinTime,
          totalDuration: data?.totalDuration
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast(error.toString());
      });
  };

  // update watched data of user
  updateUserDetails = () => {
    const data = {
      campaigncontact: this.state.detailId
    };
    const apiUrl = `${BASE_URL}${WATCHED_WEBINAR}`;
    fetch(apiUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .catch((error) => {
        toast(error.toString());
      });
  };

  // Manage ads modal
  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  // close ads and clear ads
  closeAdsModal = () => {
    this.setState(() => ({
      AdsModal: false,
      currentAd: ''
    }));
  };

  componentDidMount() {
    this.fetchVideoUrl();
  }

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  handleProgress = (state) => {
    this.setState(state);
    const { playedSeconds } = state;
    const currentAd = this.state.ads.find(ad => playedSeconds >= ad.adStartTime && playedSeconds < ad.adEndTime);
    console.debug("Current Time", playedSeconds)
    // Open and close ads
    if (!!currentAd && !this.state.showAds && !!currentAd?.adTitle) {
      console.debug("Current Ads if", currentAd)
      this.setState({ currentAd, showAds: true });
      this.toggleModal();
    } else if (!currentAd && this.state.showAds) {
      console.debug("Current Ads else", currentAd)
      this.setState({ showAds: false, showModal: false });
    }
    // Handle 80% watch of video
    if (Math.round(playedSeconds) === Math.round(this.state.webinarMinTime)) {
      this.updateUserDetails();
    }
    // Webinar section ended
    if (Math.round(playedSeconds) >= Math.round(this.state.totalDuration)) {
      this.setState({ playing: false })
    }
  };

  handleBeforeUnload = (event) => {
    const confirmationMessage = "Are you sure you want to leave this page?";
    event.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  handleUnload = () => {
    navigator.sendBeacon('/log-tab-close', JSON.stringify({ tabClosed: true }));
  };

  handleDuration = (duration) => {
    this.setState({ duration });
  };

  handleRightClick = (event) => {
    event.preventDefault();
  };

  handleToggleFullScreen = () => {
    const player = document.getElementById("react-player");
    if (player) {
      if (!this.state.isFullScreen) {
        if (player.requestFullscreen) {
          player.requestFullscreen();
        } else if (player.mozRequestFullScreen) {
          player.mozRequestFullScreen();
        } else if (player.webkitRequestFullscreen) {
          player.webkitRequestFullscreen();
        } else if (player.msRequestFullscreen) {
          player.msRequestFullscreen();
        }
        this.setState({ isFullScreen: false });
      } else {
        this.setState({ isFullScreen: false });
        if (document.fullscreenElement) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
          this.setState({ isFullScreen: false });
        }
      }
    }
  };

  handleMouseEnter = () => {
    if (!this.state.isHovered) {
      this.setState({ isHovered: true });

    }
  };

  handleMouseLeave = () => {
    if (!!this.state.isHovered) {
      this.setState({ isHovered: false });

    }
  };

  render() {
    return (
      <div className="container">
        <div className="head"></div>
        {(!this.state?.isLoading || !this.state?.liveTitle) && (<HeadText state={this.state} />)}
        {(!!this.state?.isLoading || !this.state?.url) && (
          <div className="loader">
            <DNA
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
        {!!this.state?.playing && this.state?.url && !this.state?.isLoading && (
          <div className="video-container">
            <span onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}
            >
              <div className="react-players" >
                <ReactPlayer
                  id="react-player"
                  className="react-player"
                  url={`${this.state?.url}#t=${this.state?.videoInterval}`}
                  playing={this.state?.playing}
                  controls={false}
                  volume={this.state?.volume}
                  muted={this.state?.muted}
                  onProgress={this.handleProgress}
                  onDuration={this.handleDuration}
                  width={this.state?.isFullScreen ? "100%" : "-webkit-fill-available"}
                  height={this.state?.isFullScreen ? "100%" : "auto"}
                  onContextMenu={this.handleRightClick}
                  config={{
                    file: {
                      attributes: {
                        disablePictureInPicture: true,
                      },
                    },
                  }}
                />
              </div>

              {this.state.isHovered && (
                <>
                  <div className="sound-icon" onClick={this.handleToggleMuted}>
                    {this.state?.muted ? (
                      <img src={require('./assets/icons/mute.png')} alt="Muted" />
                    ) : (
                      <img src={require('./assets/icons/volume.png')} alt="Volume" />
                    )}
                  </div>
                  <div
                    className="fullscreen-icon"
                    onClick={this.handleToggleFullScreen}
                  >
                    <img src={require('./assets/icons/fullscreen.png')} alt="Fullscreen" />
                  </div>
                </>
              )}
            </span>
          </div>
        )}
        {!this.state?.playing && <WaitForNextSession />}
        {!this.state?.playing && this.state?.videoInterval === 0 && <Comingsoon />}
        {!this.state?.isLoading && this.state?.author && this.state?.playing && (
          <div className="description-section">
            <div className="description-div">
              <div className="profile-image">
                <img src={this.state?.authorImage} alt="Fetching" />
              </div>
              <DescriptionText state={this.state} />
            </div>
          </div>
        )}

        <AdsModal
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          state={this.state}
        />

        <ToastContainer position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          theme="colored" />
      </div>
    );
  }
}

export default App;
