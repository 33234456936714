import i18next from "i18next";
import { initReactI18next } from "react-i18next";

// Import your translation files
import translationEn from "./assets/i18n/en.json";
import translationDe from "./assets/i18n/de.json";

// Get the browser language
const browserLanguage = navigator.language || navigator.userLanguage;

// Create the i18n instance
const i18nextInstance = i18next.createInstance();

// Initialize i18next
i18nextInstance.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEn,
    },
    de: {
      translation: translationDe,
    },
  },
  lng: browserLanguage.split("-")[0], // Set the default language based on the browser language
  fallbackLng: "de", // Fallback language if translation is missing or browser language is not supported
  interpolation: {
    escapeValue: false,
  },
});

export default i18nextInstance;
